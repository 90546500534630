const moduleContent2Images = {
  name: 'moduleContent2Images',

  transform: (data) => {
    const { fields } = data
    return {
      entryTitle: fields.sectionName,
      sys: data.sys,
      overlayType: fields.overlayType,
      eyebrow: fields.eyebrow,
      eyebrow2: fields.eyebrow2,
      headline: fields.headline,
      headline2: fields.headline2,
      bodyText: fields.bodyText,
      bodyText2: fields.bodyText2,
      generalPaddingDisable: fields.generalPaddingDisable,
      backgroundImage: fields.backgroundImage?.fields?.file?.url
        ? 'https:' + fields.backgroundImage.fields.file.url
        : '',
      altTag: fields?.backgroundImage?.fields?.file
        ? fields?.backgroundImage?.fields?.description
        : '',
      firstBackgroundLink: fields.firstBackgroundLink,
      backgroundImage2: fields.backgroundImage2?.fields?.file?.url
        ? 'https:' + fields.backgroundImage2.fields.file.url
        : '',
      secondBackgroundLink: fields.secondBackgroundLink,
      ctaLeft1: {
        entryTitle: fields?.ctaLeft1?.fields?.entryTitle,
        label: fields?.ctaLeft1?.fields?.label,
        url: fields?.ctaLeft1?.fields?.url,
        openInNewTab: fields?.ctaLeft1?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaLeft1?.fields?.openVideoPlayer,
        id: fields?.ctaLeft1?.sys?.id,
        backgroundColor: fields?.ctaLeft1?.fields?.backgroundColor,
      },
      ctaRight1: {
        entryTitle: fields?.ctaRight1?.fields?.entryTitle,
        label: fields?.ctaRight1?.fields?.label,
        url: fields?.ctaRight1?.fields?.url,
        openInNewTab: fields?.ctaRight1?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaRight1?.fields?.openVideoPlayer,
        id: fields?.ctaRight1?.sys?.id,
        backgroundColor: fields?.ctaRight1?.fields?.backgroundColor,
      },
      ctaLeft2: {
        entryTitle: fields?.ctaLeft2?.fields?.entryTitle,
        label: fields?.ctaLeft2?.fields?.label,
        url: fields?.ctaLeft2?.fields?.url,
        openInNewTab: fields?.ctaLeft2?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaLeft2?.fields?.openVideoPlayer,
        id: fields?.ctaLeft2?.sys?.id,
        backgroundColor: fields?.ctaLeft2?.fields?.backgroundColor,
      },
      ctaRight2: {
        entryTitle: fields?.ctaRight2?.fields?.entryTitle,
        label: fields?.ctaRight2?.fields?.label,
        url: fields?.ctaRight2?.fields?.url,
        openInNewTab: fields?.ctaRight2?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaRight2?.fields?.openVideoPlayer,
        id: fields?.ctaRight2?.sys?.id,
        backgroundColor: fields?.ctaRight2?.fields?.backgroundColor,
      },
      layout: fields?.layout ? fields?.layout : 'Default',
      stackOrSlide: fields?.stackOrSlide,
      // deprecated - replaced by primary and secondary cta
      ctaLeftText: fields.ctaLeftText,
      ctaLeftText2: fields.ctaLeftText2,
      ctaRightUrl: fields.ctaRightUrl,
      ctaRightText: fields.ctaRightText,
      ctaRightUrl2: fields.ctaRightUrl2,
      ctaRightText2: fields.ctaRightText2,
      ctaLeftUrl: fields.ctaLeftUrl,
      ctaLeftUrl2: fields.ctaLeftUrl2,
    }
  },
}
export default moduleContent2Images
