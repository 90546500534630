import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const moduleAccordionSection = {
  name: 'moduleAccordionSection',

  transform: (data) => {
    const { fields } = data

    return {
      entryTitle: fields.entryTitle,
      sectionTitle: fields.sectionTitle,
      sectionDescription: documentToReactComponents(fields.sectionDescription),
      accordionTitle: fields.accordionTitle,
      accordionDescription: documentToReactComponents(fields.accordionDescription),
      sectionMarginTop: fields.sectionMarginTop,
      tabsTitle: fields?.tabsTitle,
      cta: {
        entryTitle: fields?.cta?.fields?.entryTitle,
        label: fields?.cta?.fields?.label,
        url: fields?.cta?.fields?.url,
        openInNewTab: fields?.cta?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta?.fields?.openVideoPlayer,
        id: fields?.cta?.sys?.id,
        backgroundColor: fields?.cta?.fields?.backgroundColor
      },
      buttonTitle: fields.buttonTitle,
      buttonUrl: fields.buttonUrl,
      accordionElements: fields.accordionElements
        ? fields.accordionElements.map((content, index) => {
            const {
              groupTitle,
              elementTitle,
              elementDescription,
              elementImage,
              ctaLeft,
              ctaRight,
            } = content?.fields || {}

            return {
              id: index,
              elementGroupTitle: groupTitle || null,
              elementTitle: elementTitle || null,
              elementDescription: documentToReactComponents(elementDescription) || null,
              elementImage: elementImage ? `http:${elementImage?.fields?.file?.url}` : null,
              ctaLeft: {
                label: ctaLeft?.fields?.label,
                url: ctaLeft?.fields?.url,
                openInNewTab: ctaLeft?.fields?.openInNewTab,
                openVideoPlayer: ctaLeft?.fields?.openVideoPlayer,
                id: ctaLeft?.sys?.id,
                backgroundColor: ctaLeft?.fields?.backgroundColor
              },
              ctaRight: {
                label: ctaRight?.fields?.label,
                url: ctaRight?.fields?.url,
                openInNewTab: ctaRight?.fields?.openInNewTab,
                openVideoPlayer: ctaRight?.fields?.openVideoPlayer,
                id: ctaRight?.sys?.id,
                backgroundColor: ctaRight?.fields?.backgroundColor
              },
              // deprecated - replaced by ctaLeft, ctaRight
              buttonLeftTitle: content?.fields?.buttonLeftTitle,
              buttonLeftUrl: content?.fields?.buttonLeftUrl || '/',
              buttonRightTitle: content?.fields?.buttonRightTitle,
              buttonRightUrl: content?.fields?.buttonRightUrl || '/',
            }
          })
        : [],
    }
  },
}
export default moduleAccordionSection
