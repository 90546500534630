const module3UpText = {
  name: 'moduleGlobalBrands',

  transform: (data) => {
    const { fields } = data
    return {
      entryTitle: fields.entryTitle,
      buttonTitle: fields.buttonTitle,
      inset: fields.inset,
      sectionName: fields.sectionName,
      headline: fields.headline,
      headline2: fields.headline2,
      headline3: fields.headline3,
      bodyText: fields.bodyText,
      bodyText2: fields.bodyText2,
      bodyText3: fields.bodyText3,
      cta1: {
        entryTitle: fields?.cta1?.fields?.entryTitle,
        label: fields?.cta1?.fields?.label,
        url: fields?.cta1?.fields?.url,
        openInNewTab: fields?.cta1?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta1?.fields?.openVideoPlayer,
        id: fields?.cta1?.sys?.id,
        backgroundColor: fields?.cta1?.sys?.backgroundColor
      },
      cta2: {
        entryTitle: fields?.cta2?.fields?.entryTitle,
        label: fields?.cta2?.fields?.label,
        url: fields?.cta2?.fields?.url,
        openInNewTab: fields?.cta2?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta2?.fields?.openVideoPlayer,
        id: fields?.cta2?.sys?.id,
        backgroundColor: fields?.cta2?.sys?.backgroundColor
      },
      cta3: {
        entryTitle: fields?.cta3?.fields?.entryTitle,
        label: fields?.cta3?.fields?.label,
        url: fields?.cta3?.fields?.url,
        openInNewTab: fields?.cta3?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta3?.fields?.openVideoPlayer,
        id: fields?.cta3?.sys?.id,
        backgroundColor: fields?.cta3?.sys?.backgroundColor
      },
      generalTitle: fields.generalTitle,
      generalSubtitle: fields.generalSubtitle,
      // deprecated - replaced by cta1, cta2, cta3
      ctaRightUrl: fields.ctaRightUrl,
      ctaRightText: fields.ctaRightText,
      ctaRightUrl2: fields.ctaRightUrl2,
      ctaRightText2: fields.ctaRightText2,
      ctaRightText3: fields.ctaRightText3,
      ctaRightUrl3: fields.ctaRightUrl3,
    }
  },
}
export default module3UpText
