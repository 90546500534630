import { isEmpty } from '@/lib/helper'

const modulePageHeader = {
  name: 'modulePageHeader',
  transform: (data) => {
    const { fields, sys } = data

    return {
      entryTitle: fields.entryTitle,
      headline: fields.headline,
      overlineText: fields.overlineText,
      backgroundImage: {
        url: fields.backgroundImage.fields.file.url,
      },
      textPosition: fields.textPosition,
      fullWidth: fields.fullWidth,
      fullHeight: isEmpty(fields.fullHeight) ? false : fields.fullHeight,
      textOnly: fields.textOnly,
      authorName: isEmpty(fields.author) ? null : fields.author.fields.name,
      date: isEmpty(fields.date) ? null : fields.date,
      timeToRead: isEmpty(fields.timeToRead) ? null : fields.timeToRead,
    }
  },
}

export default modulePageHeader
