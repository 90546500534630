import { isEmpty } from "@/lib/helper"

const moduleContentBlock = {
  name: 'moduleContentBlock',
  transform: (data) => {

    const { fields, sys } = data

    return {
      entryTitle: fields.entryTitle,
      title: fields.title,
      bodyText: fields.bodyText,
      textAlignment: isEmpty(fields.textAlignment) ? 'left' : fields.textAlignment,
      insert: fields.insert
    }
  }
}

export default moduleContentBlock
