import { fetchGraphQL } from '@/lib/contentful/api';


const modulePromoVideo = {
  name: "modulePromoVideo",

  transform: (data, preview = false) => {

    const { fields } = data;

    return {
      entryTitle: fields.entryTitle,
      inset: fields.inset,
      overlayType: fields.overlayType,
      eyebrow: fields.eyebrow,
      headline: fields.headline,
      backgroundImage: fields.backgroundImage.fields.file.url ? "https:"+fields.backgroundImage.fields.file.url : '',
    };
  },
  
enablePreview: async(module, preview = false) => {
    console.log("preview enabled",module);
    let id = module.sys.id;
    const entry = await fetchGraphQL(
      `query {
        modulePromoVideo(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          inset
          overlayType
          backgroundImage{
            url
          }
          eyebrow
          headline      
      }
    }`,preview
    )
    console.log("entry data promo---",entry)
    return entry?.data?.modulePromoVideo;
  }
};
export default modulePromoVideo;
