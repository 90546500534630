import { isEmpty } from "@/lib/helper"

const moduleContent3up = {
  name: 'moduleContent3up',
  transform: (data) => {

    const { fields, sys } = data

    if (fields.sectionType == 'Content / 3 Related Blocks') {
      const blocks = []
      
      blocks = fields.sectionBlock.map(field => {
        const block = field.fields
        return {
          title:fields.title,
          backgroundImage: {
            url: block.backgroundImage?.fields?.file.url,
          },
          eyebrow: isEmpty(block.eyebrow) ? null : block.eyebrow,
          headline: isEmpty(block.headline) ? null : block.headline,
        }
      })
      return {'blocks': blocks}
    } else if (fields.sectionType == 'Content / 3-Up Featured' ) {
      const sectionBlock1 = fields.sectionBlock[0]?.fields || null
      const sectionBlock2 = fields.sectionBlock[1]?.fields || null
      const sectionBlock3 = fields.sectionBlock[2]?.fields || null

      return {
        entryTitle: fields.entryTitle,
        overlayType: fields.overlayType ? fields.overlayType : 'Dark',
        backgroundImageLeft: {
          url: sectionBlock1.backgroundImage.fields?.file.url,
        },
        headlineLeft: isEmpty(sectionBlock1.eyebrow) ? null : sectionBlock1.eyebrow,
        bodyTextLeft: isEmpty(sectionBlock1.headline) ? null : sectionBlock1.headline,
        bodyTextSecondLeft: isEmpty(sectionBlock1.bodyText) ? null : sectionBlock1.bodyText,
        ctaLeftTextLeft: isEmpty(sectionBlock1.ctaLeftText) ? null : sectionBlock1.ctaLeftText,
        ctaLeftUrlLeft: isEmpty(sectionBlock1.ctaLeftUrl) ? '/' : sectionBlock1.ctaLeftUrl,
        ctaLeftTextRight: isEmpty(sectionBlock1.ctaRightText) ? null : sectionBlock1.ctaRightText,
        ctaLeftUrlRight: isEmpty(sectionBlock1.ctaRightUrl) ? '/' : sectionBlock1.ctaRightUrl,

        headlineRight: sectionBlock2 ? isEmpty(sectionBlock2.eyebrow) ? null : sectionBlock2.eyebrow : null,
        bodyTextRight: sectionBlock2 ? isEmpty(sectionBlock2.headline) ? null : sectionBlock2.headline : null,
        bodyTextSecondRight: sectionBlock2 ? isEmpty(sectionBlock2.bodyText) ? null : sectionBlock2.bodyText : null,
        ctaRightTextLeft: sectionBlock2 ? isEmpty(sectionBlock2.ctaLeftText) ? null : sectionBlock2.ctaLeftText : null,
        ctaRightUrlLeft: sectionBlock2 ? isEmpty(sectionBlock2.ctaLeftUrl) ? '/' : sectionBlock2.ctaLeftUrl : '/',

        headlineRight2: sectionBlock3 ? isEmpty(sectionBlock3.eyebrow) ? null : sectionBlock3.eyebrow : null,
        bodyTextRight2: sectionBlock3 ? isEmpty(sectionBlock3.headline) ? null : sectionBlock3.headline : null,
        bodyTextSecondRight2: sectionBlock3 ? isEmpty(sectionBlock3.bodyText) ? null : sectionBlock3.bodyText : null,
        ctaRightTextLeft2: sectionBlock3 ? isEmpty(sectionBlock3.ctaLeftText) ? null : sectionBlock3.ctaLeftText : null,
        ctaRightUrlLeft2: sectionBlock3 ? isEmpty(sectionBlock3.ctaLeftUrl) ? '/' : sectionBlock3.ctaLeftUrl : '/',

        // generalSectionTitle: isEmpty(fields.generalSectionTitle) ? null : fields.generalSectionTitle,
        // descriptionAboveTitle: isEmpty(fields.descriptionAboveTitle) ? null : fields.descriptionAboveTitle,
      }
    } else if (fields.sectionType == 'Global / Brand Promise' || 'Global / Brand Promise Insert' ) {
      const sectionBlock1 = fields.sectionBlock[0]?.fields || null
      const sectionBlock2 = fields.sectionBlock[1]?.fields || null
      const sectionBlock3 = fields.sectionBlock[2]?.fields || null

      return {
        entryTitle: fields.entryTitle,
        title: isEmpty(fields.title) ? null : fields.title,
        subTitle: isEmpty(fields.subTitle) ? null : fields.subTitle,
        buttonTitle: isEmpty(fields.buttonTitle) ? null : fields.buttonTitle,
        headlineLeft: isEmpty(sectionBlock1.eyebrow) ? null : sectionBlock1.eyebrow,
        bodyTextLeft: isEmpty(sectionBlock1.headline) ? null : sectionBlock1.headline,
        bodyTextSecondLeft: isEmpty(sectionBlock1.bodyText) ? null : sectionBlock1.bodyText,
        ctaLeftTextLeft: isEmpty(sectionBlock1.ctaLeftText) ? null : sectionBlock1.ctaLeftText,
        ctaLeftUrlLeft: isEmpty(sectionBlock1.ctaLeftUrl) ? '/' : sectionBlock1.ctaLeftUrl,
        ctaLeftTextRight: isEmpty(sectionBlock1.ctaRightText) ? null : sectionBlock1.ctaRightText,
        ctaLeftUrlRight: isEmpty(sectionBlock1.ctaRightUrl) ? '/' : sectionBlock1.ctaRightUrl,

        headlineRight: sectionBlock2 ? isEmpty(sectionBlock2.eyebrow) ? null : sectionBlock2.eyebrow : null,
        bodyTextRight: sectionBlock2 ? isEmpty(sectionBlock2.headline) ? null : sectionBlock2.headline : null,
        bodyTextSecondRight: sectionBlock2 ? isEmpty(sectionBlock2.bodyText) ? null : sectionBlock2.bodyText : null,
        ctaRightTextLeft: sectionBlock2 ? isEmpty(sectionBlock2.ctaLeftText) ? null : sectionBlock2.ctaLeftText : null,
        ctaRightUrlLeft: sectionBlock2 ? isEmpty(sectionBlock2.ctaLeftUrl) ? '/' : sectionBlock2.ctaLeftUrl : '/',

        headlineRight2: sectionBlock3 ? isEmpty(sectionBlock3.eyebrow) ? null : sectionBlock3.eyebrow : null,
        bodyTextRight2: sectionBlock3 ? isEmpty(sectionBlock3.headline) ? null : sectionBlock3.headline : null,
        bodyTextSecondRight2: sectionBlock3 ? isEmpty(sectionBlock3.bodyText) ? null : sectionBlock3.bodyText : null,
        ctaRightTextLeft2: sectionBlock3 ? isEmpty(sectionBlock3.ctaLeftText) ? null : sectionBlock3.ctaLeftText : null,
        ctaRightUrlLeft2: sectionBlock3 ? isEmpty(sectionBlock3.ctaLeftUrl) ? '/' : sectionBlock3.ctaLeftUrl : '/' }
      } else {
      return {
        content: 'Content Type undefined'
      }
    }
  }
}

export default moduleContent3up
