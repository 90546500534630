const moduleGiftCard = {
    name: 'moduleGiftCard',
  
    transform: (data) => {
      const { fields } = data
      return {
        cardName: fields?.name,
        defaultAmount: fields?.amount,
        markdownText: fields?.description?.fields?.markdownText,
        cardImage: fields?.images[0]?.fields,
        inputFieldTitle: fields?.inputFieldTitle?.map((item)=>item?.fields) ?? [],
        minAmountLimit : fields?.minAmountLimit,
        maxAmountLimit : fields?.maxAmountLimit,
        maxGiftCardAmountPerCart : fields?.maxGiftCardAmountPerCart || 0
      }
    },
  }
  export default moduleGiftCard
  
