import moduleContentBlock from './moduleContentBlock'
import moduleHero from './moduleHero'
import moduleContent from './moduleContent'
import moduleContent3up from './moduleContent3up'
import moduleImageGalleryUp from './moduleImageGalleryUp'
import modulePageHeader from './modulePageHeader'
import moduleAccordionSection from './moduleAccordionSection'
import moduleSliders from '@/lib/contentful/adapters/moduleSliders'
import moduleHeroSection from '@/lib/contentful/adapters/moduleHeroSection'
import moduleHero1upSection from '@/lib/contentful/adapters/moduleHero1upSection'
import moduleHeroBanner from '@/lib/contentful/adapters/moduleHeroBanner'
import modulePromoVideo from '@/lib/contentful/adapters/modulePromoVideo'
import moduleContent2Blocks from '@/lib/contentful/adapters/moduleContent2Blocks'
import moduleContent3Blocks from '@/lib/contentful/adapters/moduleContent3Blocks'
import moduleContent2Images from '@/lib/contentful/adapters/moduleContent2Images'
import moduleGlobalBrands from '@/lib/contentful/adapters/module3UpText'
import moduleRelatedArticles from '@/lib/contentful/adapters/moduleRelatedArticles'
// import modulePageDescription from "@/lib/contentful/adapters/modulePageDescription";
import moduleHeroText from '@/lib/contentful/adapters/moduleHeroText'
import moduleProTextContent from '@/lib/contentful/adapters/moduleProTextContent'
import moduleFeaturedBuyBox from '@/lib/contentful/adapters/moduleFeaturedBuyBox'
import moduleGiftCard from '@/lib/contentful/adapters/moduleGiftCard'

const Adapter = (moduleName, data, preview = false) => {
  const modules = [
    moduleHeroSection, // new module
    moduleAccordionSection, // new module
    moduleSliders, // new module
    moduleHero1upSection, // new module
    moduleContentBlock,
    //moduleHero,
    moduleContent,
    moduleContent3up,
    moduleImageGalleryUp,
    modulePageHeader,
    moduleHeroBanner,
    modulePromoVideo,
    moduleContent2Blocks,
    moduleContent3Blocks,
    moduleContent2Images,
    moduleGlobalBrands, // 3up text
    moduleRelatedArticles,
    // modulePageDescription
    moduleHeroText,
    moduleProTextContent,
    moduleFeaturedBuyBox,
    moduleGiftCard
  ]
  let selectedModule = modules.find(
    (module) => module.name.toLowerCase() === moduleName.toLowerCase()
  )
  if (selectedModule && !preview) {
    //call function conditionally if preview is enabled
    // please don't remove this code
    // if(preview){
    //   console.log('--preview--',data)
    //    return  selectedModule.enablePreview(data,preview);
    // }else{
    return selectedModule.transform(data, preview)
    // }
  }

  return data
}

export default Adapter
