import { isEmpty } from "@/lib/helper"

const moduleContent = {
  name: 'moduleContent',
  transform: (data) => {
    const { fields, sys } = data
    
    if (
      fields.sectionType == 'Hero / Content' ||
      fields.sectionType == 'Content / Image Left - half' ||
      fields.sectionType == 'Content / Image Right - Half' ||
      fields.sectionType == 'Content / Image Left - Third' ||
      fields.sectionType == 'Content / Image Right - Third'
    ) {
      const sectionBlock = fields.sectionBlock[0].fields

      return {
        entryTitle: fields.entryTitle,
        backgroundImage: {
          url: sectionBlock.backgroundImage.fields?.file.url,
        },
        generalSectionTitle: isEmpty(fields.generalSectionTitle) ? null : fields.generalSectionTitle,
        generalSectionEyebrow: isEmpty(fields.generalSectionEyebrow) ? null : fields.generalSectionEyebrow,
        generalSectionSubtitle: isEmpty(fields.generalSectionSubtitle) ? null : fields.generalSectionSubtitle,
        generalPaddingDisable: fields.generalPaddingDisable,
        descriptionAboveTitle: isEmpty(fields.descriptionAboveTitle) ? null : fields.descriptionAboveTitle,
        bodyText: isEmpty(sectionBlock.bodyText) ? null : sectionBlock.bodyText,
        ctaLeftText: isEmpty(sectionBlock.ctaLeftText) ? null : sectionBlock.ctaLeftText,
        ctaLeftUrl: isEmpty(sectionBlock.ctaLeftUrl) ? '/' : sectionBlock.ctaLeftUrl,
        ctaRightText: isEmpty(sectionBlock.ctaRightText) ? null : sectionBlock.ctaRightText,
        ctaRightUrl: isEmpty(sectionBlock.ctaRightUrl) ? '/' : sectionBlock.ctaRightUrl,
        eyebrow: isEmpty(sectionBlock.eyebrow) ? null : sectionBlock.eyebrow,
        headline: isEmpty(sectionBlock.headline) ? null : sectionBlock.headline,
        overlayType: sectionBlock.overlayType ? sectionBlock.overlayType : null,
        inset: fields.inset? true : false,
        // sectionType: isEmpty(fields.sectionType) ? null : fields.sectionType,
      }
    } else if (
      fields.sectionType == 'Content / Image Left - Fourth' ||
      fields.sectionType == 'Content / Image Right - Fourth'
    ) {
      const sectionBlock = fields.sectionBlock[0].fields

      return {
        entryTitle: fields.entryTitle,
        backgroundImage: sectionBlock.backgroundImage.fields?.file.url || '',
        generalSectionTitle: isEmpty(fields.generalSectionTitle) ? null : fields.generalSectionTitle,
        generalSectionEyebrow: isEmpty(fields.generalSectionEyebrow) ? null : fields.generalSectionEyebrow,
        generalSectionSubtitle: isEmpty(fields.generalSectionSubtitle) ? null : fields.generalSectionSubtitle,
        generalPaddingDisable: fields.generalPaddingDisable,
        descriptionAboveTitle: isEmpty(fields.descriptionAboveTitle) ? null : fields.descriptionAboveTitle,
        content: isEmpty(sectionBlock.bodyText) ? null : sectionBlock.bodyText,
        ctaLeftText: isEmpty(sectionBlock.ctaLeftText) ? null : sectionBlock.ctaLeftText,
        ctaLeftUrl: isEmpty(sectionBlock.ctaLeftUrl) ? '/' : sectionBlock.ctaLeftUrl,
        eyebrow: isEmpty(sectionBlock.eyebrow) ? null : sectionBlock.eyebrow,
        headline: isEmpty(sectionBlock.headline) ? null : sectionBlock.headline,
        overlayType: sectionBlock.overlayType ? sectionBlock.overlayType : null,
        inset: fields.inset? true : false,
        type: 'image-xs',
      }
    } else if (
      fields.sectionType == 'Hero / Secondary 2-Up' ||
      fields.sectionType == 'Promo / 2-Up' || 
      fields.sectionType == 'Content / 2-Up' 
    ) {
      const sectionBlock1 = fields.sectionBlock[0]?.fields || null
      const sectionBlock2 = fields.sectionBlock[1]?.fields || null

      return {
        entryTitle: fields.entryTitle,
        generalPaddingDisable: fields.generalPaddingDisable,
        inset: fields.inset,
        overlayType: fields.overlayType ? fields.overlayType : 'Dark',
        // first block
        backgroundImageLeft: sectionBlock1.backgroundImage.fields?.file.url,
        eyebrowLeft: isEmpty(sectionBlock1.eyebrow) ? null : sectionBlock1.eyebrow,
        headlineLeft: isEmpty(sectionBlock1.headline) ? null : sectionBlock1.headline,
        bodyTextLeft: isEmpty(sectionBlock1.bodyText) ? null : sectionBlock1.bodyText,
        ctaLeftTextLeft: isEmpty(sectionBlock1.ctaLeftText) ? null : sectionBlock1.ctaLeftText,
        ctaLeftUrlLeft: isEmpty(sectionBlock1.ctaLeftUrl) ? '/' : sectionBlock1.ctaLeftUrl,
        ctaLeftTextRight: isEmpty(sectionBlock1.ctaRightText) ? null : sectionBlock1.ctaRightText,
        ctaLeftUrlRight: isEmpty(sectionBlock1.ctaRightUrl) ? '/' : sectionBlock1.ctaRightUrl,
        // second block
        backgroundImageRight: sectionBlock2.backgroundImage.fields?.file.url,
        eyebrowRight: isEmpty(sectionBlock2.eyebrow) ? null : sectionBlock2.eyebrow,
        headlineRight: isEmpty(sectionBlock2.headline) ? null : sectionBlock2.headline,
        bodyTextRight: isEmpty(sectionBlock2.bodyText) ? null : sectionBlock2.bodyText,
        ctaRightTextLeft: isEmpty(sectionBlock2.ctaLeftText) ? null : sectionBlock2.ctaLeftText,
        ctaRightUrlLeft: isEmpty(sectionBlock2.ctaLeftUrl) ? '/' : sectionBlock2.ctaLeftUrl,
        ctaRightTextRight: isEmpty(sectionBlock2.ctaRightText) ? null : sectionBlock2.ctaRightText,
        ctaRightUrlRight: isEmpty(sectionBlock2.ctaRightUrl) ? '/' : sectionBlock2.ctaRightUrl,
      }
    } else {
      return {
        // backgroundImage: {
        //   url: fields.backgroundImage.fields.file.url,
        // },
        // overlayType: fields.overlayType,
        entryTitle: fields.entryTitle,
        eyebrow: isEmpty(fields.eyebrow) ? null : fields.eyebrow,
        headline: fields.headline,
        inset: fields.inset? true : false,
        // bodyText: isEmpty(fields.bodyText) ? null : fields.bodyText,
        // ctaLeftText: isEmpty(fields.ctaLeftText) ? null : fields.ctaLeftText,
        // ctaLeftUrl: isEmpty(fields.ctaLeftUrl) ? '/' : fields.ctaLeftUrl,
        // ctaRightText: isEmpty(fields.ctaRightText) ? null : fields.ctaRightText,
        // ctaRightUrl: isEmpty(fields.ctaLeftUrl) ? '/': fields.ctaLeftUrl,
        // type: fields.type,
        // useParallaxBackground: isEmpty(fields.useParallaxBackground) ? false : fields.useParallaxBackground,
        // inset: isEmpty(fields.inset) ? false : fields.inset,
        // useCtaLeftAsPlayButton: isEmpty(fields.useCtaLeftAsPlayButton) ? false : fields.useCtaLeftAsPlayButton,
        // useCtaRightAsPlayButton: isEmpty(fields.useCtaRightAsPlayButton) ? false : fields.useCtaRightAsPlayButton,
        // autoplayVideo: isEmpty(fields.autoplayVideo) ? false : fields.autoplayVideo
      }
    }
  }
}

export default moduleContent
