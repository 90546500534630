const moduleHeroBanner = {
  name: 'moduleHeroBanner',

  transform: (data) => {
    const { fields } = data;

    return {
      entryTitle: fields.entryTitle,
      inset: fields?.inset,
      backgroundImage: fields?.backgroundImage?.fields?.file? 'https:'+fields?.backgroundImage?.fields?.file.url: '',
      overlayType: fields?.overlayType,
      eyebrow: fields?.eyebrow,
      headline: fields?.headline,
      contentPosition: fields?.contentPosition,
      fullHeight: fields?.fullHeight,
      bodyText: fields?.bodyText,
      contentPositionOnMobile: fields?.contentPositionOnMobile,
      subHeadline: fields.subHeadline
    }
  }
}
export default moduleHeroBanner